.form {
    
    margin: 0 auto;
  }

  .form-container {
    text-align: left;
    max-width: 500px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  @media (max-width: 600px) {
    .form {
      max-width: 100%;
      padding: 0 5px;
    }
  }
  