.dee{
    background-color: blueviolet;
}



.head-padd{
    padding-right: 5vh;
}

.padd{
    margin: 4%;
    padding: 4%;
}

.foo{
    
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(237, 240, 244);
    
}
ul {
    list-style-type: circle;
}

.div-padd{
   margin-left: 10%;
   margin-right: 10%;
}